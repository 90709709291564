.feed {
  margin-top: 30px;
  &__todo {
    list-style: none;
    background-color: $blue1;
    padding: 8px 12px 8px 8px;
    margin-bottom: 40px;
    color: $blue3;
  }
  &__event {
    padding: 0;
    list-style: none;
    li {
      border: 1px solid $colorGrey3;
      padding: 10px;
    }
  }
  &__application {
    border: 1px solid $colorGrey3;
    padding: 5px;
    margin: 5px;
    border-radius: 4px;
  }
}
.post {
  /*box-shadow: 0px 0px 15px -1px $colorGrey3; */
  border: 1px solid $colorGrey3;
  border-radius: 10px;

  margin-bottom: 10px;
  &__header {
    display: flex;
    align-items: center;
  }
  &__banner {
    border-color: $colorGrey3;
    border-style: solid;
    border-width: 1px 0;
    width: 100%;
  }
  &__body {
    padding: 15px;
    word-break: break-word;
  }
}
