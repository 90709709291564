.r- {
  &header {
    padding: 10px 0;
    &__logout {
      color: $colorError;
    }
  }
  &nav {
    background-color: $colorLight;
    border-bottom: 0.5px solid $colorGrey3;
    box-shadow: 0px 0px 4px 1px $colorGrey3;
    z-index: 99999;
    &__logo {
      width: 25px;
    }
    &__search {
      width: 100%;
      padding-right: 20px;
      font-size: 1.2rem;
      min-width: 250px;
      & + span {
        position: absolute;
        top: 4px;
        right: 20px;
        color: $colorGrey7;
      }
    }
    &__link {
      color: $colorDark;
      padding: 10px 24px;
      display: inline-block;
      transition: color 0.1s ease-in-out, background-color 0.3s ease-in-out;
      &:hover {
        text-decoration: none;
        background-color: $blue1;
      }
      &.active {
        text-decoration: none;
        color: $colorLight;
        background-color: $blue3;
      }
    }
    &__icon {
      width: 24px;
      padding: 8px 10px;
      border: 0;
      background-color: transparent;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;
      position: relative;
      box-sizing: content-box;
      .st0 {
        transition: opacity 0.3s ease-in-out;
      }
      path {
        transition: fill 0.3s ease-in-out;
      }
      svg {
        height: 20px;
      }
      &--active,
      &:hover {
        outline: none;
        .st0 {
          opacity: 0.8;
        }
        #icon__{
          &notification {
            .st4 {
              fill: #122a41;
            }
          }
          &conversations {
            .st6 {
              fill: #122a41;
            }
            .st7 {
              fill: #fff;
            }
          }
          &filemanager {
            .st3 {
              fill: #122a41;
            }
          }
          &calendar {
            .st8 {
              fill: #0066cc;
            }
            .st10 {
              fill: #fff;
            }
          }
        }
      }
      &__count {
        position: absolute;
        top: 8px;
        right: 0;
        background-color: $colorError;
        border-radius: 50%;
        width: 17px;
        height: 15px;
        color: $colorLight;
        line-height: 1.4em;
        font-size: 0.7em;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    &__active {
      background-color: $colorGrey3;
    }
    &__dropdown {
      min-width: 280px;
      margin-left: -248px;
      z-index: 99999 !important;
    }
    &__dropdown_btn.btn-secondary:not(:disabled):not(.disabled) {
      &,
      &:hover,
      &:focus,
      &:active {
        background: none;
        border: none;
        color: $blue3;
        font-size: 1.4rem;
        box-shadow: none;
      }
    }
  }
}

.notifications {
  width: 370px;
  font-family: $fontHeading;
  max-width: none;
  border: none;
  box-shadow: 0 0 27px 2px rgba($color: $colorDark, $alpha: 0.06);
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 300;
  max-height: 300px;
  overflow: auto;
  .popover-body {
    padding: 0;
  }
  &__header {
    font-weight: 500;
  }
  li {
    border-bottom: 1px solid $colorGrey2;
    padding: 5px 0;
    cursor: pointer;
    &:last-child {
      border: none;
      margin-bottom: 0;
    }
    &:hover {
      background-color: $colorGrey1;
    }
    &.notifications--unread {
      background-color: $blue1;
      color: $blue3;
      &:hover {
        background-color: $blue2;
      }
    }
    &__active {
      background-color: $colorGrey3;
    }
    &__dropdown {
      min-width: 280px;
      margin-left: -248px;
    }
    &__dropdown_btn,
    &__dropdown_btn:hover,
    &__dropdown_btn:focus {
      background: none;
      border: none;
      color: #000000;
      font-size: 1.4rem;
      box-shadow: none;
    }
  }
}

.notifications {
  width: 370px;
  font-family: $fontHeading;
  max-width: none;
  border: none;
  box-shadow: 0 0 27px 2px rgba($color: $colorDark, $alpha: 0.06);
  border-radius: 0;
  font-size: 1.2rem;
  font-weight: 300;
  max-height: 300px;
  overflow: auto;
  .popover-body {
    padding: 0;
  }
  &__header {
    font-weight: 500;
  }
  li {
    border-bottom: 1px solid $colorGrey2;
    padding: 5px 0;
    cursor: pointer;
    &:last-child {
      border: none;
      margin-bottom: 0;
    }
    &:hover {
      background-color: $colorGrey1;
    }
    &.notifications--unread {
      background-color: $blue1;
      color: $blue3;
      &:hover {
        background-color: $blue2;
      }
    }
  }
}
