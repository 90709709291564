.intraction .timeline {
    list-style: none;
    padding: 0 0 10px;
    margin-bottom: 0;
    position: relative;
    &:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 3px;
      background-color: #d6d6d6;
      left: 0%;
    }
    > li {

      position: relative;
      > {
        .timeline-panel {
          margin-left: 35px;
          border: 1px solid #d4d4d4;
          border-radius: 3px;
          padding: 15px;
          background-color: #fff;
          position: relative;
          -webkit-box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
          box-shadow: 0 1px 6px rgba(0, 0, 0, 0.175);
          &:before {
            position: absolute;
            top: 26px;
            right: -15px;
            display: inline-block;
            border-top: 15px solid transparent;
            border-left: 15px solid #ccc;
            border-right: 0 solid #ccc;
            border-bottom: 15px solid transparent;
            content: " ";
          }
          &:after {
            position: absolute;
            top: 27px;
            right: -14px;
            display: inline-block;
            border-top: 14px solid transparent;
            border-left: 14px solid #fff;
            border-right: 0 solid #fff;
            border-bottom: 14px solid transparent;
            content: " ";
          }
        }
        .timeline-badge {
          color: #fff;
          width: 20px;
          height: 20px;
          position: absolute;
          top: 30px;
          left: 15px;
          margin-left: -24px;
          background-color: #ffcc33;
          border: 3px solid #fff;
          z-index: 100;
          border-top-right-radius: 50%;
          border-top-left-radius: 50%;
          border-bottom-right-radius: 50%;
          border-bottom-left-radius: 50%;
        }
      }
      &.timeline-inverted > .timeline-panel {
        &:before {
          border-left-width: 0;
          border-right-width: 15px;
          left: -15px;
          right: auto;
        }
        &:after {
          border-left-width: 0;
          border-right-width: 14px;
          left: -14px;
          right: auto;
        }
      }
    }
  }

    
.notes .role--select{
    background-color: #0066cc !important;
    color: #ffffff !important;
}

.intraction .date-section{
  margin-top: -20px !important;
  margin-bottom: 10px; 
}

.intraction .tag{
  padding: 5px 15px !important;
}
