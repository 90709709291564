.email_campaign {
  &__modal-checkbox  {
    display: contents;
  }
  &__campaign_allignment{
    vertical-align:  middle;
  }
  &__campgin-modal{
    overflow-y: scroll;
    max-height: -webkit-fill-available;
    overflow-x: hidden;
  }
  &__select-all{
    vertical-align: text-bottom;
  }

}
.recruitment-drive{
    font-size: x-large;
    font-family: poppins;
  }
