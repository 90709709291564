.student {
  &__recruitment {
    &__tab {
      padding: 10px 0;
      // overflow: hidden;
      min-height: 100vh;
    }
  }
  &__nav {
    background-color: $colorGrey6;
    color: $textLight;
    font-size: 1.2rem;
    font-weight: 400;
    padding: 15px 0;
    &__item {
      text-align: center;
      cursor: pointer;
      &::before {
        content: "|";
        position: absolute;
        bottom: 0;
        left: 0;
        font-weight: 300;
        font-size: 0.6em;
      }
      &:last-child {
        &::after {
          content: "|";
          position: absolute;
          bottom: 0;
          right: 0;
          font-weight: 300;
          font-size: 0.6em;
        }
      }
    }
    &__slider {
      height: 30px;
      line-height: 22px;
      background-color: $colorGrey1;
      border-radius: 15px;
      border: 2px solid $colorLight;
      box-shadow: 0 0 5px 2px inset rgba($color: $colorDark, $alpha: 0.16);
      padding: 1px 0;
      color: $colorGrey5;
      opacity: 1;
      &-bar {
        background-color: $colorBrandE;
        background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAKElEQVQYV2NkwA58GbGI+zIwMGxGlwALghQjS8AFkSVQBGESGIIgCQBVnAVUPcxeHAAAAABJRU5ErkJggg==");
        background-size: 5px;
        background-repeat: repeat;
        height: 100%;
        margin: 0 3px;
        border-radius: 15px;
        box-shadow: 0 0 4px 1px rgba($color: $colorDark, $alpha: 0.5);
      }
      &-placeholder {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        cursor: pointer;
        display: flex;
        font-size: 1.4rem;
        font-weight: 600;
        justify-content: center;
        align-items: center;
      }
    }
    &-toggle {
      background-color: $colorDark;
      height: 18px;
      position: relative;
      button {
        position: absolute;
        top: 50%;
        padding: 0;
        background-color: transparent;
        color: $colorGrey3;
        outline: none;
        cursor: pointer;
        border: none;
        left: 50%;
        font-weight: 300;
        font-size: 1.7rem;
        transform: translate(-50%, -50%) rotate(-90deg);
        transition: transform 0.3s ease-in-out;
      }
      &--collapsed {
        button {
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }
  }
  &__profile {
    &--noedit,
    &--freeze {
      .student__profile__image--edit {
        display: none;
      }
      // .cv-point--add {
      //   display: none;
      // }
      &.cv-point,
      & {
        .student__profile__actions {
          .student__profile__actions__btn,
          &--add {
            display: none;
          }
        }
      }
    }
    &--freeze {
      .cv-point {
        .student__profile__actions {
          .student__profile__actions__btn,
          &--add {
            display: inline;
          }
        }
      }
    }
    &__personal {
      box-shadow: 0 0 5px 2px rgba($color: $colorDark, $alpha: 0.15);
      border-radius: 3px;
      padding: 20px 15px;
    }
    &__image {
      height: 100px;
      width: auto;
      border-radius: 3px;
      &__container {
        position: relative;
        &:hover {
          .student__profile__image--edit {
            opacity: 1;
          }
        }
      }
      &--edit {
        position: absolute;
        right: 0;
        top: 0;
        padding: 1px 5px 4px 8px;
        border-radius: 0 0 0 20px;
        overflow: hidden;
        color: $blue3;
        background-color: $blue2;
        font-size: 1rem;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
      &--upload {
        position: absolute;
        right: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
      }
    }
    &__actions {
      position: absolute;
      right: 0;
      top: 0;
      font-size: 1.2rem;
      line-height: 1.6rem;
      &__btn {
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        button {
          color: $blue3;
          background-color: $blue2;
          margin: 0 3px;
          font-size: 1rem;
          padding: 1px 5px;
        }
      }
      &--add {
        white-space: nowrap;
        &::before {
          font-size: 0.9rem;
          position: absolute;
          left: 11px;
          top: 6px;
        }
        span {
          width: 0;
          transition: all 0.3s ease-in-out;
          display: inline-block;
          overflow: hidden;
          vertical-align: middle;
        }
        &:hover {
          span {
            width: 24px;
            margin-left: 10px;
          }
        }
      }
    }
    &__verification {
      padding: 0 5px;
      border-radius: 3px;
      font-weight: 500;
      text-transform: capitalize;
      width: 72px;
      display: inline-block;
      &--comment {
        width: 85px;
        &:before {
          float: right;
          padding-top: 2px;
        }
      }
      &--approved {
        color: #14c424;
        background-color: #cbf2cf;
        border: 0.5px solid #8be293;
      }
      &--rejected {
        color: #fa3709;
        background-color: #fecfc4;
        border: 0.5px solid #fd967d;
      }
      &--pending {
        color: #fc3;
        background-color: #fff4d2;
        border: 0.5px solid #ffe698;
      }
    }

    &__container {
      border-bottom: 1px solid $colorGrey3;
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;
      //padding-top: 20px;
      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &:first-child {
        padding-top: 0px;
      }
      &.r-card {
        padding: 20px 15px;
        border: 0.5px solid rgba($color: $blue5, $alpha: 0.19);
      }
      &--skill {
        .student__profile__section {
          padding-top: 0;
        }
      }
    }
    &__section {
      border-bottom: 1px solid $colorGrey3;
      padding-bottom: 20px;
      margin-bottom: 20px;
      position: relative;
      padding-top: 20px !important;
      word-break: break-word;
      &:last-child {
        border: none;
        padding-bottom: 0;
        margin-bottom: 0;
      }
      &:first-child {
        padding-top: 0px;
      }
      &--placeholder {
        font-style: italic;
        opacity: 0.45;
      }
      h2 {
        margin-bottom: 15px;
      }
      .video-resume {
        position: relative;
        i {
          background-color: $colorLight;
          color: $colorBrandD;
          font-size: 1rem;
          border-radius: 50%;
          width: 16px;
          height: 16px;
          display: inline-block;
          padding-left: 2px;
          padding-top: 1px;
          padding-bottom: 1px;
        }
        & + button {
          color: $blue3;
          background-color: $blue2;
          margin: 0 3px;
          font-size: 1rem;
          padding: 1px 5px;
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
        &__container:hover {
          button {
            opacity: 1;
          }
        }
      }
      &:hover > .student__profile__actions {
        .student__profile__actions__btn {
          opacity: 1;
        }
      }
      & > ul:hover ~ .student__profile__actions .student__profile__actions__btn {
        opacity: 0;
      }
    }
  }
  &__documents {
    width: calc(100% - 30px);
    //overflow-x: hidden;
    //border: 0.5px solid rgba($color: $blue5, $alpha: 0.19);
    max-height: calc(100vh - 80px);
    left: 100%;
    &.r-card {
      box-shadow: none;
    }
    &__list {
      list-style: none;
      padding: 0px 5px 5px 10px;
      //border-top: 1px solid $blue3;
      height: 312px;
      overflow-y: scroll;
      margin-top: -10px;
      li {
        display: flex;
        justify-content: space-between;
        padding: 10px 5px;
        cursor: pointer;
        transition: background-color 0.3s ease-in-out;
        align-items: center;
        border-bottom: 1px solid $colorGrey3;
        &:hover {
          background-color: $colorGrey1;
        }
      }
    }
  }
  &__cv {
    position: fixed;
    right: 0;
    top: 50%;
    &__preview {
      padding-top: 5%;
      & .r-btn {
        position: absolute;
        right: 0;
        transform: rotate(45deg);
      }
      .modal-dialog {
        max-width: 800px;
      }
      .cv-builder__doc {
        height: 100%;
      }
    }
  }
}
.cv-point {
  padding: 20px 5px 5px;
  position: relative;
  font-family: $fontBody;
  font-weight: 300;
  select {
    font-weight: 300;
  }
  .cv_header_input {
    font-weight: 400;
    padding-left: 12px;
    height: 100%;
  }
  &--add {
    background-color: $colorGrey2;
    border-radius: 3px;
    cursor: pointer;
    padding: 5px;
  }
  &__upload {
    border: 1px solid $colorGrey3;
    padding: 0 5px;
    margin-left: 5px;
    background-color: $colorLight;
  }
  &:hover {
    .student__profile__actions__btn {
      opacity: 1;
    }
  }
}

.resumes {
  li {
    position: relative;
    cursor: default;
  }
  &__star {
    color: $colorBrandE;
    position: absolute;
    right: 10px;
    top: 50%;
    font-size: 2rem;
    transform: translateY(-50%);
  }
}

.apply {
  &--no-edit {
    .apply__application__apply {
      display: none;
    }
  }
  &__sortby {
    .r-dropdown {
      border: none;
    }
  }
  &__tab {
    &--selected {
      border-bottom: 2px solid $colorDark;
    }
  }
  &__multirole-info {
    width: 18px;
    position: absolute;
    margin-left: 10px;
    cursor: pointer;
  }
  &__jobs {
    &__header {
      background-color: $colorLight;
      display: flex;
      justify-content: center;
      padding: 2px;
      border-top: 1px solid $colorGrey3;
    }
    &__company {
      border-top: 2px solid $colorGrey2;
      &:first-child {
        border: none;
      }
      &.apply__jobs {
        &__multirole {
          border: none;
        }
      }
    }
    &__status {
      background-color: $blue;
      color: $colorLight;
      font-size: 1.2rem;
      padding: 5px 10px;
      border-radius: 7px;
      box-shadow: 0 0 10px 3px #ccc;
      cursor: pointer;
      transition: transform 100ms;
      &:hover {
        transform: scale(1.04);
      }
      &:active {
        transform: scale(0.95);
      }
    }
    &__profile {
      margin-top: 10px;
      margin-bottom: 10px;
    }
  }
  &__application {
    background-color: $blue2;
    padding: 30px;
    left: 0;
    width: 100%;
    &__step {
      display: flex;
      flex-direction: column;
      border-left: 1px solid;
      padding-left: 25px;
      margin-left: 15px;
      height: 100%;
      &::before {
        content: "■";
        position: absolute;
        top: -5px;
        left: 24px;
        font-size: 24px;
      }
      &-next {
        flex: 1 1 100%;
        position: relative;
        width: 2px;
        background: $colorDark;
        &::before {
          content: "";
          position: absolute;
          width: 6px;
          height: 6px;
          left: -2px;
          top: 0;
          background: $colorDark;
        }
      }
    }
    &__tab {
      font-size: 1.2rem;
      font-weight: 600;
      padding: 0;
      border-bottom: 2px solid transparent;
      border-radius: 0;
      &.active {
        border-color: $colorDark;
      }
    }
    &__resume {
      background-color: rgba($color: $colorGrey4, $alpha: 0.6);
      color: $colorLight;
      padding: 5px;
      text-align: center;
      font-size: 1.2rem;
      height: 100%;
      cursor: pointer;
      word-break: break-word;
      &.active {
        background-color: rgba($color: $colorBrandA, $alpha: 0.6);
      }
    }
  }
  &__performance {
    display: flex;
    font-size: 1.2rem;
    align-items: flex-end;
    &__step {
      flex: 0 0 14.28%;
      &:last-child {
        .apply__performance__track {
          &::after {
            content: none;
          }
        }
      }
      &--done {
        .apply__performance__track {
          button {
            background-color: $colorBrandE;
            border-color: $colorBrandE;
          }
        }
      }
    }
    &__track {
      display: flex;
      align-items: center;
      button {
        flex: 0 0 5px;
        background: $colorGrey1;
        padding: 3px;
        border: 1px solid $colorGrey3;
        border-radius: 10px;
      }
      &::after {
        content: "";
        flex: 1 1 100%;
        height: 2px;
        background-color: $colorGrey3;
      }
    }
  }
}
.recruitment_nav_collapse {
  //position: absolute;
  width: 100%;
  top: 42px;
  // z-index: 99;
  box-shadow: 0 2px 10px 2px #d6d6d6;
}
.recruitment_nav_button {
  height: 200px;
}
