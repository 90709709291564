$dark:				#000411;
$black:				#353535;
$white:				#FCFCFC;
$green:				#03ab13;
$red:					#fa3709;
$toggleColor : $colorBrandB;
$height: 1.4rem;
$width: 3rem;
$off-state: 	$red;
$on-state: 		$green;

$react-blue:	#61dafb;

.switch{
	position: relative;
	width: $width;
	height: $height;
	border:1px solid $colorGrey5;
	border-radius: 12px;
	transition: background-color 100ms ease-out;
	z-index: 1;

}
.toggle-button{
	/*position: absolute;*/
	width: 1.2rem;
	height: 1.2rem;
	/*background-color: $toggleColor;*/
	border-radius: 50%;
	transition: transform 100ms ease-in-out;
	z-index: 3;
	/*border: 0.05rem solid $toggleColor;*/
	top: -0.05rem;
}
// State

.switch_is-off{
	background-color: #fed3c9;
	-webkit-box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.13);
    -moz-box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.13);
    box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.13);
}

.switch_is-on{
	background-color: #bee8c2;
	-webkit-box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.13);
    -moz-box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.13);
    box-shadow: inset 0px 0px 2px 1px rgba(0,0,0,0.13);
}

.toggle-button_position-left{
	transform: translateX(-0.1rem);
	margin-left:1px;
	padding:1px;
	
	
	background-color: $off-state;
}

.toggle-button_position-right{
	transform: translateX(($width - $height));
	margin-right:1.5px;
	padding:1px;
	
	background-color: $on-state;
}
