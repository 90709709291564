/*
    Flaticon icon font: Flaticon
    Creation date: 21/02/2019 13:06
    */

@font-face {
  font-family: "Flaticon";
  src: url("../assets/font/Flaticon.eot");
  src: url("../assets/font/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../assets/font/Flaticon.woff2") format("woff2"), url("../assets/font/Flaticon.woff") format("woff"),
    url("../assets/font/Flaticon.ttf") format("truetype"), url("../assets/font/Flaticon.svg#Flaticon") format("svg");
  font-weight: normal;
  font-style: normal;
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: "Flaticon";
    src: url("../assets/font/Flaticon.svg#Flaticon") format("svg");
  }
}

    .fi:before{
        display: inline-block;
  font-family: "Flaticon";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  line-height: 1;
  text-decoration: inherit;
  text-rendering: optimizeLegibility;
  text-transform: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
    }

    .flaticon-bin:before { content: "\f100"; }
.flaticon-bold:before { content: "\f101"; }
.flaticon-italic:before { content: "\f102"; }
.flaticon-letter-u:before { content: "\f103"; }
.flaticon-text:before { content: "\f104"; }
.flaticon-right-align:before { content: "\f105"; }
.flaticon-text-1:before { content: "\f106"; }
.flaticon-text-2:before { content: "\f107"; }
.flaticon-text-3:before { content: "\f108"; }
.flaticon-order:before { content: "\f109"; }
.flaticon-menu:before { content: "\f10a"; }
.flaticon-table:before { content: "\f10b"; }
.flaticon-width:before { content: "\f10c"; }
.flaticon-typography:before { content: "\f10d"; }
.flaticon-art:before { content: "\f10e"; }
.flaticon-school:before { content: "\f10f"; }
.flaticon-edit:before { content: "\f110"; }
.flaticon-info:before { content: "\f111"; }
.flaticon-file:before { content: "\f112"; }
.flaticon-security:before { content: "\f113"; }
.flaticon-star:before { content: "\f114"; }
.flaticon-plus:before { content: "\f115"; }
.flaticon-width-1:before { content: "\f116"; }
.flaticon-height:before { content: "\f117"; }
.flaticon-hidden:before { content: "\f118"; }
.flaticon-document:before { content: "\f119"; }
.flaticon-save:before { content: "\f11a"; }
.flaticon-duplicate:before { content: "\f11b"; }
.flaticon-expand:before { content: "\f11c"; }
.flaticon-compress:before { content: "\f11d"; }
.flaticon-minimize:before { content: "\f11e"; }
.flaticon-expand-1:before { content: "\f11f"; }
.flaticon-plus-1:before { content: "\f120"; }
.flaticon-arrow:before { content: "\f121"; }
.flaticon-arrow-1:before { content: "\f122"; }
.flaticon-box:before { content: "\f123"; }
.flaticon-worlwide:before { content: "\f124"; }
.flaticon-square:before { content: "\f125"; }
.flaticon-gear:before { content: "\f126"; }
.flaticon-pin:before { content: "\f127"; }
.flaticon-subject:before { content: "\f128"; }
.flaticon-birth:before { content: "\f129"; }
.flaticon-phone:before { content: "\f12a"; }
.flaticon-mail:before { content: "\f12b"; }
.flaticon-linkedin:before { content: "\f12c"; }
.flaticon-cat:before { content: "\f12d"; }
.flaticon-paper:before { content: "\f12e"; }
.flaticon-multimedia:before { content: "\f12f"; }
.flaticon-folder:before { content: "\f130"; }
.flaticon-folder-1:before { content: "\f131"; }
.flaticon-calendar:before { content: "\f132"; }
.flaticon-edit-1:before { content: "\f133"; }
.flaticon-chat:before { content: "\f134"; }
.flaticon-upload:before { content: "\f135"; }
.flaticon-comment:before { content: "\f136"; }
.flaticon-work:before { content: "\f137"; }
.flaticon-user:before { content: "\f138"; }
.flaticon-exit:before { content: "\f139"; }
.flaticon-like:before { content: "\f13a"; }
.flaticon-plus-2:before { content: "\f13b"; }
.flaticon-portfolio:before { content: "\f13c"; }
.flaticon-eye:before { content: "\f13d"; }
.flaticon-search:before { content: "\f13e"; }
.flaticon-edit-2:before { content: "\f13f"; }
.flaticon-typography-1:before { content: "\f140"; }
.flaticon-icons8-uppercase-filled-50-1:before { content: "\f141"; }
.flaticon-icons8-lowercase-2-filled-50:before { content: "\f142"; }
.flaticon-sencase:before { content: "\f143"; }
.flaticon-ui:before { content: "\f144"; }
.flaticon-text-4:before { content: "\f145"; }
.flaticon-align-center:before { content: "\f146"; }
.flaticon-vertical-align:before { content: "\f147"; }
.flaticon-align:before { content: "\f148"; }
.flaticon-align-1:before { content: "\f149"; }
.flaticon-vertical-alignment:before { content: "\f14a"; }
.flaticon-vertical-alignment-1:before { content: "\f14b"; }
.flaticon-vertical-alignment-2:before { content: "\f14c"; }
.flaticon-document-1:before { content: "\f14d"; }
.flaticon-alert:before { content: "\f14e"; }
.flaticon-close:before { content: "\f14f"; }
    
    $font-Flaticon-bin: "\f100";
    $font-Flaticon-bold: "\f101";
    $font-Flaticon-italic: "\f102";
    $font-Flaticon-letter-u: "\f103";
    $font-Flaticon-text: "\f104";
    $font-Flaticon-right-align: "\f105";
    $font-Flaticon-text-1: "\f106";
    $font-Flaticon-text-2: "\f107";
    $font-Flaticon-text-3: "\f108";
    $font-Flaticon-order: "\f109";
    $font-Flaticon-menu: "\f10a";
    $font-Flaticon-table: "\f10b";
    $font-Flaticon-width: "\f10c";
    $font-Flaticon-typography: "\f10d";
    $font-Flaticon-art: "\f10e";
    $font-Flaticon-school: "\f10f";
    $font-Flaticon-edit: "\f110";
    $font-Flaticon-info: "\f111";
    $font-Flaticon-file: "\f112";
    $font-Flaticon-security: "\f113";
    $font-Flaticon-star: "\f114";
    $font-Flaticon-plus: "\f115";
    $font-Flaticon-width-1: "\f116";
    $font-Flaticon-height: "\f117";
    $font-Flaticon-hidden: "\f118";
    $font-Flaticon-document: "\f119";
    $font-Flaticon-save: "\f11a";
    $font-Flaticon-duplicate: "\f11b";
    $font-Flaticon-expand: "\f11c";
    $font-Flaticon-compress: "\f11d";
    $font-Flaticon-minimize: "\f11e";
    $font-Flaticon-expand-1: "\f11f";
    $font-Flaticon-plus-1: "\f120";
    $font-Flaticon-arrow: "\f121";
    $font-Flaticon-arrow-1: "\f122";
    $font-Flaticon-box: "\f123";
    $font-Flaticon-worlwide: "\f124";
    $font-Flaticon-square: "\f125";
    $font-Flaticon-gear: "\f126";
    $font-Flaticon-pin: "\f127";
    $font-Flaticon-subject: "\f128";
    $font-Flaticon-birth: "\f129";
    $font-Flaticon-phone: "\f12a";
    $font-Flaticon-mail: "\f12b";
    $font-Flaticon-linkedin: "\f12c";
    $font-Flaticon-cat: "\f12d";
    $font-Flaticon-paper: "\f12e";
    $font-Flaticon-multimedia: "\f12f";
    $font-Flaticon-folder: "\f130";
    $font-Flaticon-folder-1: "\f131";
    $font-Flaticon-calendar: "\f132";
    $font-Flaticon-edit-1: "\f133";
    $font-Flaticon-chat: "\f134";
    $font-Flaticon-upload: "\f135";
    $font-Flaticon-comment: "\f136";
    $font-Flaticon-work: "\f137";
    $font-Flaticon-user: "\f138";
    $font-Flaticon-exit: "\f139";
    $font-Flaticon-like: "\f13a";
    $font-Flaticon-plus-2: "\f13b";
    $font-Flaticon-portfolio: "\f13c";
    $font-Flaticon-eye: "\f13d";
    $font-Flaticon-search: "\f13e";
    $font-Flaticon-edit-2: "\f13f";
    $font-Flaticon-typography-1: "\f140";
    $font-Flaticon-icons8-uppercase-filled-50-1: "\f141";
    $font-Flaticon-icons8-lowercase-2-filled-50: "\f142";
    $font-Flaticon-sencase: "\f143";
    $font-Flaticon-ui: "\f144";
    $font-Flaticon-text-4: "\f145";
    $font-Flaticon-align-center: "\f146";
    $font-Flaticon-vertical-align: "\f147";
    $font-Flaticon-align: "\f148";
    $font-Flaticon-align-1: "\f149";
    $font-Flaticon-vertical-alignment: "\f14a";
    $font-Flaticon-vertical-alignment-1: "\f14b";
    $font-Flaticon-vertical-alignment-2: "\f14c";
    $font-Flaticon-document-1: "\f14d";
    $font-Flaticon-alert: "\f14e";
    $font-Flaticon-close: "\f14f";