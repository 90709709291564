.settings{
  &__placement-activities{
    // min-height: 230px;
    // overflow: auto;
  }

  &__comming-soon{
    font-size: 9px;
  }

  &__settings-icon{
    font-size:  17px;
  }

  &__button-color{
    color: #124B84;
  }
  &__button-cursor{
    cursor: pointer;
  }
  &__multifield-update{
    background-color: #f5f5f5;
    border: 0.5px solid #e5e5e5;
    font-weight: 300;
    padding: 5px;
    outline: none;
  }
  &__info-icon{
    width: 10px;
    position: absolute;
    bottom: 9px;
  }
  &__button{
    position: relative;
  }
  &__email {
    .r-multifield {
      margin: 0;
      padding: 0;
      &__list {
        margin-bottom: 0;
      }
    }
    .r-inputfield{
      padding: .4rem;
      font-size: 1.2rem;
      border: 0;
    }
  }
  &__excel_checkbox{
    vertical-align: middle;
  }
}
