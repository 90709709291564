.company-name {
  border: 0px solid $colorGrey3;
  border-style: solid;
  border-color: transparent;
  border-width: 1px 0;
  transition: transform 0.2s ease-in-out, border 0.2s ease-in-out;
  &:focus {
    position: relative;
    //z-index: 1;
  }
}

.company-name:hover {
  border-top: 1px solid $colorGrey3;
  border-bottom: 1px solid $colorGrey3;
  // transform: scale(1.01);
  //background-color: $colorGrey1;
}
.add_process_company_btn {
  border-style: dashed !important;
  border-color: #6a7b7b !important;
  font-weight: 800;
  color: #94a7b9;
  border-radius: 6px;
  font-style: italic;
  background-color: #eaf3f3;
}

.company-delete {
  display: none;
}

.company-name:hover .company-delete {
  display: block;
}

.processes {
  .add-count {
    font-weight: 400;
    padding: 0 8px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    i:last-child {
      font-size: 1rem;
    }
  }
  &__company {
    // border-top: 1px solid $colorGrey3;
    margin-top: 8px;
    padding: 10px 0;
    & > div {
      padding: 0 5px !important;
    }
    &__roles {
      //   position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: $colorBrandE;
      //   top: 50%;
      //   left: 50%;
      //   transform: translate(-50%, -50%);
      &::before {
        content: "";
        width: 2px;
        top: calc(-50% - 5px);
        left: calc(50% - 1px);
        height: calc(100% + 5px);
        z-index: -1;
        position: absolute;
        background: $colorGrey3;
      }
      &::after {
        content: "";
        top: calc(50% - 1px);
        height: 2px;
        width: 50%;
        z-index: -1;
        position: absolute;
        background: $colorGrey3;
      }
    }
  }
  &__meeting-status {
    background-color: $colorBrandJ;
    padding: 10px 10px;
    border-radius: 7px;
    transition: transform 100ms;
    cursor: pointer;
    position: relative;
    &:hover {
      transform: scale(1.04);
      z-index: 1;
    }
    &:active {
      transform: scale(0.95);
      z-index: 1;
    }
    &__contact {
      background-color: $colorLight;
      border: none;
      font-size: 1.2rem;
      font-weight: 500;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      .r-dropdown__select {
        padding-top: 0;
        padding-bottom: 0;
      }
      i {
        top: 2px;
      }
    }
    &__date {
      background-color: #d4b8f1;
      font-size: 1.2rem;
      text-align: center;
      padding: 0 2px;
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
  &__application {
    background-color: $blue3;
    padding: 10px 10px;
    border-radius: 7px;
    transition: transform 100ms;
    margin-top: 10px;
    cursor: pointer;
    &:first-child {
      margin-top: 0;
    }
    &:hover {
      transform: scale(1.04);
    }
    &:active {
      transform: scale(0.95);
    }
    &__role {
      background-color: $colorLight;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding: 0 5px;
      font-weight: 600;
    }
    &__status {
      margin: 0 5px;
      display: inline-flex;
      align-items: center;
      &::after {
        content: "";
        display: inline-block;
        width: 8px;
        height: 8px;
        border: 1px solid;
        border-radius: 4px;
        margin-left: 2px;
      }
      &--1 {
        &::after {
          background-color: $colorBrandH;
          border-color: $colorBrandH;
        }
      }
      &--2 {
        &::after {
          background-color: $colorLight;
          border-color: $colorBrandH;
        }
      }
      &--3 {
        &::after {
          background-color: $colorLight;
          border-color: $colorBrandD;
        }
      }
      &--4 {
        &::after {
          background-color: $colorLight;
          border-color: $colorBrandF;
        }
      }
    }
    &__create {
      margin-top: 10px;
      height: 38px;
      width: 100%;
      background-color: $colorGrey1;
      color: $blue3;
      font-weight: 600;
      border: 1px solid $colorGrey3;
      &:first-child {
        margin-top: 0;
      }
    }
    &__detail {
      background-color: $blue1;
      padding: 30px 15px 15px;
    }
    &__post {
      background-color: $colorLight;
      margin: 10px 0;
    }
    &__company {
      display: flex;
      &__contact {
        flex: 1 1 50%;
        background-color: $colorGrey3;
        color: $colorLight;
        padding: 5px;
        margin-bottom: 10px;
        cursor: pointer;
        &:nth-child(odd) {
          margin-right: 5px;
        }
        &:nth-child(even) {
          margin-left: 5px;
        }
        &:hover,
        &.active {
          background-color: $colorBrandA;
        }
      }
    }
  }
  &__milestone {
    background-color: $blue3;
    padding: 10px;
    display: flex;
    flex-direction: column;
    &__share {
      font-size: 1.1rem;
      font-weight: 600;
      position: absolute;
      top: 0;
      cursor: pointer;
      display: flex;
      left: 50%;
      white-space: nowrap;
      background-color: $blue3;
      color: $colorLight;
      z-index: 1;
      align-items: center;
      border-radius: 0 0 4px 4px;
      padding: 2px 5px;
      transform: translateX(-50%);
      display: none;
      &--disabled {
        pointer-events: none;
      }
      img {
        width: 10px;
        margin-right: 2px;
      }
    }
    &__item {
      position: relative;
      background-color: rgba($color: $colorLight, $alpha: 0.6);
      border-radius: 3px;
      padding: 5px;
      margin-top: 10px;
      transition: background-color 0.2s ease-in-out;
      &:first-child {
        padding: 5px 0 0;
      }

      &:hover {
        background-color: $colorLight;
        .processes__milestone__share {
          display: block;
        }
      }
      h4 {
        line-height: 1;
      }
      .r-btn {
        padding: 0 5px;
        font-size: 1.2rem;
        background-color: $blue5;
        border-radius: 3px;
        &.add-btn {
          font-size: 1.6rem;
          background-color: transparent;
        }
      }
    }
    &__desc {
      padding: 5px;
      background-color: $blue2;
      width: 100%;
      border: none;
      outline: none;
      text-align: left;
      font-size: 1.2rem;
      font-weight: 500;
      margin-top: 1px;
      &:focus {
        outline: none;
      }
    }
  }
  &__chatbox {
    flex: 1 1 50%;
    display: flex;
    flex-direction: column;
    box-shadow: 0 0 10px -3px $colorGrey3;
    &__messages {
      flex: 1 1 90%;
      background-color: $colorLight;
    }
    &__actions {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 5px 2px;
      background-color: $colorLight;
      label {
        margin-bottom: 0;
      }
    }
  }
  &__table {
    &__1 {
      font-size: 1.2rem;
      margin-top: 20px;
      border-bottom: 1px solid $colorGrey4;
      tr {
        border-top: 1px solid $colorGrey4;
        background-color: $colorGrey2;
        td {
          border: none;
        }
        &.disabled-row {
          opacity: 0.5;
        }
      }
    }
    &__2 {
      thead {
        tr {
          background-color: $colorGrey3;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $colorGrey4;
          td {
            border: none;
          }
        }
      }
    }
  }
  &__meeting {
    background-color: $colorGrey2;
    padding: 30px 15px 15px;
    &__contact {
      background-color: $colorGrey8;
      padding: 5px;
      color: $colorLight;
      font-size: 1.2rem;
      font-weight: 300;
      margin-top: 10px;
      border-radius: 3px;
    }
    &__profile {
      text-align: center;
      padding: 8px;
      background-color: $colorLight;
      border-radius: 3px;
      box-shadow: 0px 0px 5px 1px $colorGrey3;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: space-around;
      span {
        display: block;
        background-color: $colorBrandE;
        border-radius: 5px;
        padding: 5px;
        font-size: 1.2rem;
        font-weight: 500;
        position: relative;
        button {
          position: absolute;
          right: 0;
          top: 2px;
        }
      }
    }
  }
  &__verifiers {
    padding-left: 0;
    list-style: none;
    margin-top: 20px;
    li {
      background-color: $colorLight;
      padding: 10px;
      transition: background-color 0.3s ease-in-out;
      cursor: pointer;
      &.active,
      &:hover {
        background-color: $colorGrey1;
      }
    }
  }
  &__students {
    padding-left: 0;
    list-style: none;
    margin-top: 20px;
    li {
      background-color: $colorLight;
      padding: 5px 10px;
      border-top: 1px solid $colorGrey3;
    }
  }
}

.modal {
  &__post {
    &__header {
      font-size: 1.4rem;
      font-weight: 600;
      border-bottom: 3px solid transparent;
      padding: 0 10px;
      display: inline-block;
      &.active {
        border-color: $colorBrandB;
      }
    }
    &__attachment {
      background-color: $colorGrey2;
      display: flex;
      min-height: 120px;
      align-items: center;
      justify-content: center;
      margin: 0 -10px;
      span {
        font-size: 4rem;
        margin-left: 12px;
        color: $colorGrey5;
      }
    }
    &__fields {
      .r-inputfield {
        width: 100%;
        background-color: transparent;
        border-width: 0 0 1px;
      }
      .r-rte .ql-container {
        background-color: transparent;
      }
    }
  }
  &__title {
    border-bottom: 2px solid $colorDark;
  }
}
.company {
  &-card {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    transition: all 0.3s;

    //box-shadow: 0 0 8px 0px rgba(0, 0, 0, 0.1);
    &-selected {
      transform: scale(1.02);
      border: 1px thin #06c;
      border-radius: 4px;
      box-shadow: 0 0 6px 0px #ebf3fb;
      background-color: #ebf3fb;
    }
  }
}
.company_meeting_status{
  .processes__meeting-status{
    transform: scale(1);
    pointer-events: none;
    .flaticon-arrow{
      display: none;
    }
  }
}
.contact-card {
  height: 100%;

  &-header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
  }
  &-body {
    min-height: 200px;
    height: 100%;
    position: relative;
    padding-bottom: 40px;
  }
  &-footer {
    position: absolute;
    bottom: 0;
  }
  .student__profile__actions__btn {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }
  &:hover {
    .student__profile__actions__btn {
      opacity: 1;
    }
  }
}
.green-selected {
  color: #03ab13;
}
.multiple_role{
  margin-top: -5px;
}
.multipleRoleBar::before{
  content: " ";
  height: 30px;
  width: 20px;
  background: #ff0000;
  position: absolute;left: 50%;
  top: -20px;
  z-index: -1;
}

.date_font{
  font-size: 11px;
}
