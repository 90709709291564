.college{
  &__collegeprofile {
    &__image {
      height: 150px;
      width: auto;
      border-radius: 3px;
      &__container {
        position: relative;
        &:hover {
          .college__profile__image--edit {
            opacity: 1;
          }
        }
      }
    }
  }
  &_navSide {
    border-radius: 3px;
    color: $blue3;
    border: 0.5px solid rgba($color: $blue5, $alpha: 0.19);
    overflow-x: hidden;
    //border-top-left-radius: 10px;
    max-height: calc(100vh - 80px);
    transition: all 250ms ease-in-out 0s;
  }
  &_text-end{
    text-align: end;
  }
}

.react-pdf__Page__canvas{
  width: 100%!important;
  height: auto!important;
}
.recharts-wrapper, .recharts-wrapper svg{
  width: 100%!important;
}