.r-btn {
  font-family: $fontHeading;
  font-weight: 500;
  padding: 3px 15px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  background-color: transparent;
  color: inherit;
  &:focus {
    outline: 0;
  }
  &:disabled {
    cursor: not-allowed;
  }
  &--no-radius {
    border-radius: 0;
  }
  &--brandA {
    background-color: $colorBrandA;
    color: $colorLight;
  }
  &--brandB {
    background-color: $colorBrandB;
    color: $colorLight;
  }
  &--brandI {
    background-color: $colorBrandI;
    color: $colorLight;
  }
  &--blue-3 {
    background-color: $blue3;
    color: $colorLight;
  }
  &--dark {
    color: $colorLight;
    background-color: $colorDark;
    font-weight: 600;
  }

  &--dark-v {
    color: $colorLight;
    background-color: #252525;
    font-weight: 600;
    margin: 0 ;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid;
  }
  &--outline-dark-v {
    color: #000000;
    background-color: #ffffff;
    font-weight: 600;
    margin: 0 ;
    padding: 3px;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid;
  }

  &--grey {
    background-color: $colorGrey3;
  }

  &--link {
    background-color: transparent;
    font-weight: 300;
    padding: 0;
  }

  &--link-blue {
    background-color: transparent;
    font-weight: 300;
    padding: 0;
    color: $blue3;
  }

  &--blue {
    background-color: $blue2;
    color: $blue3;
  }


}

.btn-profile-download{
  text-decoration: none !important
}
