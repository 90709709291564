.tableheader{
    background: #c4dcf3;
}
.tableheadbox{
	min-width: 168px;
    text-align: center;
    padding: 6px;
	font-weight:400;
	text-align:left;
}
.tableheadboxsearch{
	 min-width: 330px;
  font-weight:400;
    align-items: center;
    padding: 6px;
}
.borderBottomlightblack{
	border-bottom: 1px solid #f5f5f5;
}
.borderBottomBlack{
	border-bottom: 1px solid #000;
}
.tableinput{
    border: none;
    outline:none;
}
.modalclass {
    display: block;
    width: 1530px;
}
.dialogClass{
    margin-left: 200px;
}
.customButton{
     border: 2px solid #0066cc;
     border-left-radius: 20px;
     border-top-right-radius: 50px 30px;
}
.dotcircle {
  height: 25px;
  width: 25px;
  background-color: #FA3709;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
}
.detailsselect {
    width: 50px;
    background: white;
    border: hidden;
    cursor: pointer;
    outline:none;
   -moz-appearance: none;
   -webkit-appearance: none;
   appearance: none;
}
.selectfield{
    border: 0;
    cursor: pointer;
    outline:none;
    padding: 0 8px;
}
.customselect {
    background: white;
    border: hidden;
    cursor: pointer;
    outline:none;
   -moz-appearance: none;
   -webkit-appearance: none;
   appearance: none;
}
.verticalLine{
      border-left: 2px solid black;
      height: 40px;
      left: 50%;
      margin-left: -3px;
}
.titleinput{
    border: 0;
    background: none;
    border-bottom: 1px solid $colorGrey3;
}
.titledescription {
    resize: none;
    outline: none;
    border: 0;
    width: 100%;
    border-bottom: 1px solid;
}
.heeadingNavpt {
      position: sticky;
    z-index: 1000;
    top: 42px;
    background: white;
    padding-top: 10px;
}
.popoverclass{
  min-width: 300px;
}
.tablestudent{

  &__studentstatus {
    padding: 3px 0 10px;
    border-radius: 7px;
    transition: transform 100ms;
    cursor: pointer;
    position: relative;
    &:hover {
      transform: scale(1.04);
      z-index: 1;
      color: blue;
      text-decoration: underline;
    }
  }
}

.timePickermodal {
    z-index: 99999 !important;
}

.toogel_red {
  background-color: #e81f1f;
  border-color: #e81f1f;
}

.toogel_green {
  background-color:#21a533;
  border-color: #21a533;
}


.toogel_value_red {
  color: #e81f1f;
}

.toogel_value_green {
  color:#21a533;
}

.badgePerformnace {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 5px 10px;
  border-radius: 50%;
  background-color: yellow;
  color: white;
}

  .excel_setting {
    .modal-content{
      width: 75%;
    }
  }

  .message_body{
    .r-rte .ql-editor {
    padding-left: 12px;
    min-height: 130px
  }
}

.pt_settings{
  height: 100%;
  transition: transform 0.3s ease-in-out;
  &:hover {
    -webkit-box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.17);
    -moz-box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.17);
    box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.17);
    transform: scale(1.02);
  }
}
