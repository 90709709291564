
@media print {
    .cv-builder__doc__section {
        -webkit-print-color-adjust: exact; 
    }
   .left-sidenav{
        display: none;
    }
   .r-nav{
        display: none;
    }
    .recruitment_nav_collapse{
        display: none;
    }
    
    .cv-builder>.row>.col-9 {
        margin-right: auto;
        margin-left: auto;
    }
    .cv-builder__doc__overlay{
        background-color: rgba(0, 0, 0, 0); 
    }
 }