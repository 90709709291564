.border {
  &__t{
    border-top: 1px solid $colorGrey3;
    padding-top: 15px;
    margin-top: 40px;
    position: relative;
    padding-bottom: 5px;
  }
}
.spocModel1 {
  
  .modal-body{
    min-height: 200px;
  }
}

.active-tab{
  border-bottom: 2px solid;
  &-brandB{
    border-color: $colorBrandB;
  }
}
.location_icon{
  position: absolute;
  margin-left:-25px!important;
  background: black;
  color:white;
  border-radius: 0;
  width:25px;
  height:31px;
}

.child-boundry-campus-left{
  position: relative;;
  &:before{
    content: "";
      height: calc(100% - 85px);
    margin-left: 10px;
    border-left: 1px solid;
    border-left-style: dotted;
    position: absolute;
    margin-top: 35px;

  }

}
.child-boundry-campus{
  position: relative;
  &:after{
    vertical-align: middle;
    content: "";
    width: 80%;
    margin-left: 10px;
    border-top: 1px solid;
    border-top-style: dotted;
    position: absolute;
    //margin-top: 24px;


  }

}
.child-boundry-course{
  position: relative;
  &:after{
    content: "";
    width: 45%;
    margin-left: 48%;
    border-top: 1px solid;
    border-top-style: dotted;
    position: absolute;
    //margin-top: 25px;
  }
}

.border-left-white{
  border-left: 2px solid #ffffff !important;
}
.child-boundry-course-left{
  position: relative;
  &:before{
    content: "";
    height: calc(100% - 40px);
    margin-left: 75px;
    border-left: 1px solid;
    border-left-style: dotted;
    position: absolute;
    margin-top: 25px;

  }
}


.permission-btn {
  display: none;
}

.permission:hover .permission-btn{
  display: block;
}
