.calendar{

  &__event-topic{


    width: auto;
    color: #f5f5f5;
    border: none;
    cursor: pointer;
    display: flex;
  }
  &__event-topic-li{
    flex: 1 1 50%;
    text-align: center;
  }

  &__bc-calendar{
    margin: 0;
    margin-top: 30px;
    height: 500px;
  }
  &__categories{
    border: none;
    border-bottom: 2px solid #e5e5e5;
  }

  &__event-link{

    &:hover{
      text-decoration: none;
    }
  }
  &__pending-invites{
    height: 250px;
    overflow: auto;
  }
  &__save-button{
    background-color: black;
    color: #f5f5f5;
    border-radius: 0;
    &:hover{
      background-color: black;
      color: white;
    }
  }

  &__discard-button{
    background-color: white ;
    color:black;
    border-radius: 0;
    &:hover{
      background-color: transparent;
      color: black;
    }
  }
  &__topic-active-class{
    background-color: #0066cc;
    color: #ffffff;
  }
  &__side-nav{
    height: 459px;
  }
  &__description-box{
    border: none;
    background-color: #f5f5f5;
    border-radius: 3px;
  }

  &__edit-partipants{
    &__top-buttons{
      border: none;
      background-color: transparent;
      &:hover{
          border-bottom: #ef0065;
      }
      &:focus{
        outline: none;
      }
      &.active{
        outline:none;
        border: none;
        border-bottom: 3px solid #ef0065;
        background-color: transparent;
      }
    }
    &__primary-div{
      overflow: auto;
      height: 100px;
    }
  }
  &__pt-member-button{
    border: none;
    background-color: white;
    border-radius: 5px;
    &:hover{
      background-color: white;
      color:  #707070;
      border: none;
    }
  }
  &__primary-card{
    height: 220px;
    overflow: auto;
  }
  &__accept-reject{
    cursor: pointer;
  }

  &__invitation-dialog{
    position: absolute;
    top: 97px;
    right: 100px;
    bottom: 0;
    left: 0;
    z-index: 10040;
    overflow: auto;
    overflow-y: auto;
  }
  &__event-description-box .ql-editor {
    height: 100px;
  }
}
