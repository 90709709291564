.r- {
  &login {
    min-height: 100vh;
    &__logo {
      max-width: 100%;
      width: 100px;
      display: block;
    }
    &__placeholder {
      background-color: $blue3;
      background-image: url("../assets/login.png");
      background-position: bottom;
      background-size: 100% auto;
      background-repeat: no-repeat;
      &__text {
        font-size: 4.2rem;
        color: $colorLight;
        font-weight: 500;
        line-height: 1;
        padding-left: 10%;
        padding-top: 10%;
        p {
          a {
            color: $colorLight;
            text-decoration: underline;
          }
        }
      }
    }
    &__links {
      display: flex;
      list-style: none;
      padding: 20px 0 0;
      font-weight: 500;
      li {
        padding: 0 5px;
        &::before {
          content: "|";
          padding-right: 5px;
        }
        &:first-child {
          padding-left: 0;
          &::before {
            content: none;
          }
        }
        a {
          color: inherit;
        }
      }
    }
  }
}
