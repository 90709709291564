.verifier {
  position: absolute;
  &__container{
    width:100%;
  }
  &__main{
    color: #000000;
    margin-top: 0px;
    margin-bottom: 10px;
    font-family: "Poppins";
    padding-top: 0px;
    padding-bottom: 10px;
  }
  &__verify-statement{
    &__main{
      height: 100px;
      background-color: #339999;
      color: #ffffff;
      width:100%;
      margin: 0px;
      padding: 35px;
    }
}
  &__verify-count-button{
    margin-top: 20px;
    padding-top: 10px;
    margin-right: 0;

    padding-bottom: 0px;
    margin-top: 0;
    font-size: 50px;
    line-height: 20px;
    color: #0066cc;
    font-family: "Poppins";
    font-weight: 600;
    background-color: transparent;
    border: none;
    &:focus{
      outline: none;
    }
  }
  &__verify-text-button{
    margin-top: 0;
    padding-top: 0;
    background-color: transparent;
    color:black;
    border:none;
    cursor : pointer;
    &.active{
      border-bottom: 4px solid #282828;
      border-radius: 3px;
      outline: none;
      box-shadow: none;
    }
  }
  &__card-link{
    &:hover{
      text-decoration: none;
    }
  }

    &__verify-summary-card {
      cursor: pointer;
      margin-top: 30px;
      margin-right: 5px;
      margin-left: 5px;
      margin-bottom: 30px;
      width: 345px;
      height: auto;
      padding-top: 5px;
      padding-bottom: 0;
      border-radius: 3px;
      background-color: #ffffff;
      border: 1px solid #d6d6d6;
      color:#1f1d1d;
      overflow: hidden;
      transition: transform 0.3s ease-in-out;
      &:hover {
        -webkit-box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.17);
        -moz-box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.17);
        box-shadow: 0px 0px 12px -2px rgba(0,0,0,0.17);
        transform: scale(1.02);
      }

    }

    &__verify-card-button{
      width: 100%;
      margin-top: 10px;
      margin-bottom:  0;
      padding-bottom: 0;
      padding: 8px;
      background-color: #f5f5f5;

    }

    &__list{
      width: 100%;
      display: flex;
      list-style-type: none;
      padding: 0;
      margin: 0;
      margin-top: 5px;
      background-color:#ebebeb;
    }
    &__detail-scroll{
      height: 80%;
    }

    &__detail-page{
      margin: 0px;
      margin-top: 40px;
      width: 250px;
      border:  1px solid  #c9c2c2;
      padding: 0px;
      border-radius: 5px;
      display: flex;
      justify-content: space-between;
    }
      &__swipe-button{
        background-color: transparent;
        color:  #383535;
        border: none;
        height: 100%;
        &:hover{
          background-color: #eae8e8;
          border: none;
          color:  #1f1d1d;
        }
        &:focus{
          box-shadow: none;
        }
      }

      &__student-name{
        width: auto;
        margin-left: 0px;
        margin-right: 0px;
        padding: 5px;
        letter-spacing: 2px;
        padding-left:  20px;
        padding-right: 20px;
        line-height: 15px;
        }

        &__timer{
          margin-top: 40px;
          margin-left: 40px;
        }
        &__resume-button{
          margin-top: 40px;
          margin-left: 40px;
          border-radius: 3px;
          text-align: center;

          &:focus{
            box-shadow: none;
            outline: none;
          }
          &:hover{
            background-color: #339999;
          }
        }
        &__section-card{
          margin-top: 10px;
          margin-bottom: 40px;

        }

        &__card-body{
          &.active{
            background-color:#ebf3fb;
          }

        }
        &__section-card-button{
          margin: 10px;
          width: auto;
          padding: 3px;
          padding-left: 7px;
          padding-right: 7px;
          background-color: #fff;
          color:  #383535;
          border : 1px solid #c9c2c2;
          border-radius: 3px;
          &:hover{
            background-color: #eae8e8;
            color:  #383535;
            border : 1px solid #c9c2c2;
            border-radius: 3px;
          }
          &:focus{
            box-shadow: none;
            outline: none;
          }
        }
        &__section-card-button-selected{
          background-color: #aae3b0;
          color: #339999;
          &:hover{
            background-color: #aae3b0;
            color:  #339999;

        }
      }
        &__status-button-brief{
          margin: 0px;
          height: 30px;
          margin-top: 50px;
          margin-bottom: 10px;
          border-radius: 3px;
          letter-spacing: 2px;
          padding: 3px;
          padding-bottom: 10px;
          border: none;
          background-color: transparent;
          color:  #1f1d1d;

          &:hover{
            border: none;
            background-color:  transparent;
            color:  #1f1d1d;
          }
          &.active{
            border: none;
            background-color: transparent;
            color:  #1f1d1d;
            border-bottom: 3px solid #1f1d1d;

          }
          &:focus{
            box-shadow: none;
            outline: none;
          }
        }
        &__font-size{
          font-size: 1.5em;
          font-family: "Poppins";
          font-weight: 500;
        }

        &__student-proof{
          max-height: calc(100vh - 82px);
          position: fixed;
          top: 82px;
          overflow: auto;
          overflow-x: hidden;
          .carousel-control-next-icon{
            background-color: black;
            width: 50%;
            height: 50%;
          }
          .carousel-control-prev-icon{
            background-color: black;
            width: 50%;
            height: 50%;
          }
          .carousel-control-next {
            right: -12px;
            height: 50%;
            width: 60px;
            bottom: 25%;
            top: 25%;
          }
          .carousel-control-prev {
            left: -12px;
            height: 50%;
            width: 60px;
            bottom: 25%;
            top: 25%;
          }
        }

        &__student-proof-ex{
          width: 50%;
          margin: 4rem;

        }

        &__comment-box{
          border-radius: 5px;
          box-shadow: 1px 1px 10px 15px rgba(#0006cc, 0.16) ;
        }
        &__send-button{
          background-color: transparent;
          color: #0006cc;
          border: none;
          .active{
            outline: none;
          }
          .focus{
            outline: none;
          }
        }
        &__comment-body{
          height: 200px;
          overflow-x: hidden;
        }
        &__details-page-back-button{
          margin-top: 38px;
        }

        &__search-bar{
          margin-left: -30px;
          position: absolute;
        }
        &__page {
          .pagination{
            float: right;
          }
        }
  }
  .talk-bubble {
  	margin-top: 5px;
    margin-bottom: 2px;
    display: inline-block;
    position: relative;
  	width: 70%;
  	height: auto;
  	background-color: #f5f5f5;
    border-radius:  5px solid #d6d6d6;

  }
  .tri-right.border.left-top:before {
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -40px;
	right: auto;
  top: -20px;
	bottom: auto;
	border: 32px solid;
	border-color: #666 ;
}
.tri-right.left-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: -7px;
	right: auto;
  top: 0px;
	bottom: auto;
	border: 7px solid;
	border-color: #f5f5f5  transparent transparent;
}
.tri-right.right-top:after{
	content: ' ';
	position: absolute;
	width: 0;
	height: 0;
  left: auto;
	right: -7px;
  top: 0px;
	bottom: auto;
	border: 7px solid;
	border-color: #f5f5f5  transparent transparent;
}
.talktext{


	text-align: left;
  line-height: auto;

}
.talktext p{
  /* remove webkit p margins */
  -webkit-margin-before: 0em;
  -webkit-margin-after: 0em;

}
.paginationItemStyle {
  &.page-item.active .page-link {
    z-index: 1;
    color: #000;
    background-color: grey;
    border-color: grey;
  }
}
