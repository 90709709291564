.help_section{
  position: fixed;
  bottom: 150px;
  right: 22px;
    &__float{
      cursor: pointer;
      width:50px;
    	height:50px;
    	bottom:40px;
    	right: 22px;
    	background-color: black;
    	color: white;
    	border-radius:50px;
    	text-align:center;
    	box-shadow: 2px 2px 3px #999;
    	z-index:1000;
    	animation: bot-to-top 2s ease-out;
  }

  &__ul-style{
    ul{
      padding: 10px;
      list-style : none;
    }
    ul li {
      border-bottom:  1px solid black;
      padding: 5px;
      font-size: 11px;
      cursor: pointer;
    }
  }

  @keyframes bot-to-top {
      0%   {bottom:-40px}
      50%  {bottom:40px}
  }

  @keyframes scale-in {
      from {transform: scale(0);opacity: 0;}
      to {transform: scale(1);opacity: 1;}
  }
}
