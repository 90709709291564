.cv-builder {
  min-height: calc(100vh - 61px);
  background-color: $bgCVBuilder;
  margin-top: 20px;
  &__limit {
    position: absolute;
    border-top: 1px dashed $colorDark;
    width: calc(100% + 30px);
    top: 1127px;
    text-align: right;
    font-size: 1rem;
    font-weight: 600;
    pointer-events: none;
  }
  &__doc {
    background-color: $bgCV;
    padding: 20px;
    box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.05);
    // border: 0.5px solid rgba(169, 175, 209, 0.19);
    font-family: "Times New Roman";
    width: 800px;
    transform-origin: 0 0;
    &.spacing {
      &-xs {
        .row {
          margin-left: -4px;
          margin-right: -4px;
          &>div {
            padding-left: 4px;
            padding-right: 4px;
          }
        }
      }
    }
    .borderLeft {
      border-left: 1px solid;
    }
    .borderTop {
      border-top: 1px solid;
    }
    .borderRight {
      border-right: 1px solid;
    }
    .borderBottom {
      border-bottom: 1px solid;
    }
    .footer {
      margin-top: auto;
    }
    
    ul {
      list-style-type: circle;
      margin-bottom: 0;
    }
    ol {
      list-style-type: lower-alpha;
      margin-bottom: 0;
    }
    &.bullet-hyphen {
      .cv_point {
        list-style: none;
        &>p:first-child {
          display: list-item;
          list-style: square;
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 10px;
        list-style: none;
        li {
          position: relative;
          &::before {
            content: "-";
            position: absolute;
            left: -10px;
          }
      }
      }
    }
    &.bullet-square {
      .cv_point {
        list-style: none;
        &>p:first-child {
          display: list-item;
          list-style: square;
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 20px;
        list-style: square;
      }
      &-bordered {
       p + ul{
          border-top: 1px solid rgb(100, 100, 100);
          margin-left: -4px;
          margin-right: -4px;
          padding-left: 24px;
          padding-right: 4px;
        }
      }
    }
    &.bullet-disc {
      .cv_point {
        list-style: none;
        &>p:first-child {
          display: list-item;
          list-style: disc;
        }
      }
      ul {
        margin-bottom: 0;
        padding-left: 20px;
        list-style: disc;
      }
      &-bordered {
       p + ul{
          border-top: 1px solid rgb(100, 100, 100);
          margin-left: -4px;
          margin-right: -4px;
          padding-left: 24px;
          padding-right: 4px;
        }
      }
    }
    &.bullet-margin {
      ul{
        padding-inline-start: 15px;
      }
    }
    p {
      margin: 0;
    }
    [data-configpath] {
      transition: 0.3s box-shadow ease-in-out;
      &:hover,
      &:focus,
      &:active {
        outline: none;
      }
      &.highlight {
        box-shadow: 0 0 5px 1px inset $blue3;
      }
    }
    &__section {
      position: relative;
      transition: box-shadow 0.2s ease-in-out;
    }
    &__overlay {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.7);
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      opacity: 0;
      transition: opacity 0.3s ease-in-out;
      cursor: pointer;
      color: $textLight;
      &:hover {
        opacity: 1;
      }
    }
    &__tools {
      position: absolute;
      right: -8px;
      z-index: 1;
      background-color: rgba($color: $colorGrey2, $alpha: 0.9);
      top: 0px;
      font-size: 1.6rem;
      border-radius: 5px;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease-in-out;
      line-height: 1rem;
      padding: 0 5px;
      display: flex;
      &--left {
        left: -12px;
        right: auto;
      }
      button {
        padding: 3px 6px;
      }
      &__reorder {
        display: flex;
        flex-direction: column;
        button {
          font-size: 1rem;
          padding-top: 0;
          padding-bottom: 0;
        }
      }
    }
    &__hidden {
      background-color: $colorHighlightError;
      border-top: 3px solid $colorBrandB;
      position: relative;
      margin: 0 -15px;
      padding: 0 15px;
      &.cv-builder__doc__section {
        border-top-width: 5px;
        & > .cv-builder__doc__tools {
          right: 20px;
        }
        span {
          &::after {
            border-top-width: 5px;
            top: -5px;
          }
          &::before {
            top: calc(100% - 5px);
          }
        }
      }
      .hide-btn {
        display: none;
      }
      span {
        cursor: pointer;
        &::after {
          background-color: $colorBrandB;
          height: 100%;
          content: "";
          position: absolute;
          right: -30px;
          border-top: 3px solid $colorBrandB;
          width: 30px;
          top: -3px;
        }
        &::before {
          content: "";
          position: absolute;
          right: -30px;
          top: calc(100% - 3px);
          border-left: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-top: 10px solid $colorBrandB;
          border-right: 6px solid $colorBrandB;
          outline: none;
        }
      }
    }
    .collapse {
      position: relative;
    }
  }
  &__toolbar {
    background-color: $bgCVToolbar;
    border-radius: 4px;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 0px 10px 0px #ccc;

    &__accordian {
      cursor: pointer;
      padding: 10px 0 15px;
      //border-top: 1px solid #ccc;

      i {
        float: right;
        transform: rotate(90deg);
        transition: transform 0.3s ease-in-out;
      }
      &--collapsed {
        i {
          transform: rotate(-90deg);
        }
      }
    }
    .btn-link {
      color: $primary;
      font-size: 1.5rem;
      &:hover,
      &.active {
        color: $blue;
      }
    }
  }
}
.footer-bottom-child {
  &>div:first-child{
    padding-bottom: 20px;
  }
  .footer {
    width: 100%;
    position: absolute;
    bottom: 0px;
  }
}
.cv-builder__doc {
  &__section {
    & > .cv-builder__doc__tools {
      right: 7px;
      top: -3px;
    }
    &:hover > .cv-builder__doc__tools {
      opacity: 1;
      visibility: visible;
    }
  }
  .editable {
    .cv-builder {
      &__doc {
        &__overlay {
          visibility: hidden;
        }
      }
    }
    .row:hover,.cv-item:hover {
      & > .cv-builder__doc__tools {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.cv__master {
  .cv-builder {
    &__doc {
      &__tools {
        visibility: hidden !important;
      }
      &--unverified {
        background-color: rgba($color: $colorBrandE, $alpha: 0.5) !important;
        display: flex!important;
      }
      &--rejected {
        background-color: rgba($color: $colorBrandF, $alpha: 0.5) !important;
        display: flex!important;
      }
    }
  }
}
.single_page_cv{
  height : 1200px;
  overflow-y: clip;  
  .cv-builder__limit{
    z-index : 999;
    &:after {
      content: "";
      height: 100vh;
      background : #ffffff91;
      position: absolute;
      width: 100%;
      left: 0;
      z-index: -1;
    }
  }
}
.cv-builder {
  &__doc.only-verified {
    .cv-builder__doc {
      &--unverified,&--rejected {
        display: none;
      }
    }
  }
}

.preview {
  [data-configpath] {
    &:hover,
    &:focus,
    &:active,
    &.highlight {
      box-shadow: none;
      outline: none;
    }
  }
  .cv-builder {
    &__doc {
      &__overlay {
        visibility: hidden;
      }
      &__tools {
        visibility: hidden !important;
      }
      &__hidden {
        display: none;
      }
    }
  }
}

.cvHeader,.cvBlocks {
  background-color: #d9d9d9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  &--hide {
    background-color: $colorHighlightError;
  }
  & > .collapse {
    display: flex;
    height: 100%;
    & > div {
      align-self: center;
    }
  }
}
// CV Style patterns 
.cv__pattern-1>.collapse.show:nth-of-type(even)>.row {
  background-color: #f2f2f2
}



.list-to-vertical-columns {
  ul{
    display: flex;
    padding-left: 0!important;
  }
  li{
    flex: auto;
    line-height: 20px;
    display: inline-block;
    text-align: center;
    justify-content: center;
    &:not(:nth-last-of-type(1)){
      border-right: 1px solid;
    }
  }
}
