
.offer-card .nav-tabs .nav-link{
    font-weight: 600;
    border: none;
}
.offer-card  .nav-tabs .nav-link.active{
    border: none;
    border-bottom:2px solid #ef0065;
}

.offer-card .nav-tabs .nav-link p::before{
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #ffcc33;
 }
.offer-card .nav-tabs .nav-link  p.accepted_offer::before{
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    background-color: #00b10f;
 }

.arrow_box {
	position: relative;
}
.arrow_box:before {
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.arrow_box:before {
	border-bottom-color: rgba(0, 0, 0, 0.125);
	border-width: 16px;
	margin-left: -16px;
}

.withdraw_deadline{
  width: 9px;
  position: absolute;
  top: -2px;
}

.application_offers{
    height: 220px;
    overflow: auto;
    overflow-x: hidden;
}
